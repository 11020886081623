// import App from '@/vue/App.vue';
// import { createApp } from 'vue';
// Import our CSS
import '@/css/app.css';
// App main

import 'lazysizes';



import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'

// Import the component
import { ProgressiveShareButton } from 'progressive-share-button';
// Initialize the component
ProgressiveShareButton();

Alpine.plugin(focus)

window.Alpine = Alpine

function externalLinks() {
        const c = document.getElementsByTagName('a')
        let a = 0;
        for (;
            a < c.length;
            a++
        ) {
            const b = c[a];
            b.getAttribute('href') &&
            b.hostname !== location.hostname &&
            (b.target = '_blank');
        }
    }

    externalLinks();


import carousel from "./modules/carousel";
carousel();

import {initMap} from "./modules/googlemap";
initMap();

import entryEditorLinks from './modules/entry-editor-links';
entryEditorLinks()

import './modules/video-embeds'

Alpine.start()
