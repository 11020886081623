import { Loader } from "@googlemaps/js-api-loader";

const loader = new Loader({
  apiKey: "AIzaSyDV4JyqOSTamBaqyRsn_ixvQYtTkHZ-vkA",
  version: "weekly"
});

const initMap = ():void => {

  // Check for existing id of map in document
  if (document.getElementById('map')) {

    const mapElement = document.getElementById("map") as HTMLElement;

    loader.load().then(() => {
      const geocoder = new google.maps.Geocoder();
      // let icon = new google.maps.MarkerImage('/assets/images/marker@2x.png', null, null, null, new google.maps.Size(49, 53));



      const address = mapElement.dataset.address;

      geocoder.geocode({'address': address}, function (results, status) {
				if(status === google.maps.GeocoderStatus.OK && results !== null && results[0].geometry.location != null) {
					const position = results[0].geometry.location;

          const map = new google.maps.Map(mapElement, {
            center: position,
            zoom: 15,
            mapTypeId: "roadmap",
            panControl: 		false,
            zoomControl: 		false,
            mapTypeControl: 	false,
            scaleControl: 		false,
            streetViewControl: 	false,
            scrollwheel: false
          });

          const marker = new google.maps.Marker({
            map: 		map,
            icon: {
              url: '/assets/images/map-marker.png',
              size: new google.maps.Size(80, 110),
              scaledSize: new google.maps.Size(60, 82.5),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(32,75),
            },
            position: position,
          });


					marker.setPosition(position);
				} else {
					mapElement.classList.add('hidden');
				}
			});


    });

  }
}

export { initMap };
