document.addEventListener('DOMContentLoaded', (event) => {
    // Find all oembed elements
    const oembedElements = document.querySelectorAll('oembed[url]');

    oembedElements.forEach(element => {
        const url = element.getAttribute('url');
        let videoId: string | null;
        if (!url) return;
        videoId = extractYouTubeId(url);

        if (videoId) {
            // Create the iframe element for YouTube
            const iframe = document.createElement('iframe');
            iframe.setAttribute('src', `https://www.youtube.com/embed/${videoId}`);
            iframe.setAttribute('width', '560'); // Set width
            iframe.setAttribute('height', '315'); // Set height
            iframe.setAttribute('frameborder', '0');
            iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
            iframe.setAttribute('allowfullscreen', 'true');
            iframe.title = "YouTube video player";
            iframe.loading = "lazy";
            iframe.style.aspectRatio = "16/9";
            iframe.style.width = "100%";
            iframe.style.display = "block";
            iframe.style.height = "auto";
            iframe.style.margin = "1.5rem auto";

            // Replace the oembed element with the iframe
            if(element.parentNode){
                element.parentNode.replaceChild(iframe, element);
            }
        }
    });

    // Function to extract the YouTube video ID from various URL formats
    function extractYouTubeId(url:string) {
        const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const matches = url.match(regex);
        return matches ? matches[1] : null;
    }
});
