import Swiper,{ Navigation, Pagination } from 'swiper';

import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';


const carousel = () => {
  console.log('carousel initialized');

  // check for existence of '.main-carousel'

  const carousel = document.querySelector('.main-carousel');

  if (carousel) {

    new Swiper('.main-carousel', {
      // configure Swiper to use modules
      modules: [Navigation, Pagination],
      grabCursor: true,
      loop: true,
      // If we need pagination
      pagination: {
        el: '.swiper-pagination',
      },
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }
}

export default carousel;
